import React, { useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaTimes } from 'react-icons/fa';
import useOutsideClick from '../../hooks/UseOutsideClick';

interface ServiceDetail {
  name: string;
  description: string;
  image: string;
  modalDescription: string;
}

interface ServiceModalProps {
  service?: ServiceDetail;
  onClose: () => void;
}

const backdropVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 },
};

const modalVariants = {
  hidden: { opacity: 0, scale: 0.75 },
  visible: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.75 },
};

const ServiceModal: React.FC<ServiceModalProps> = ({ service, onClose }) => {

  const modalRef = useRef<HTMLDivElement>(null);
  useOutsideClick(modalRef, onClose);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEsc);

    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  if (!service) return null;

  return (
    <motion.div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      variants={backdropVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <motion.div
        className="bg-white rounded-lg overflow-hidden w-11/12 md:w-3/4 lg:w-1/2 relative"
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
        onClick={(e) => e.stopPropagation()}
        ref={modalRef}
      >
        <button
          className="absolute top-4 right-4 text-gray-700 hover:text-gray-900"
          onClick={onClose}
          aria-label="Close Modal"
        >
          <FaTimes size={24} />
        </button>

        <img
          src={service.image}
          alt={service.name}
          className="w-full h-64 object-cover"
        />

        <div className="p-6">
          <h2 className="text-xl md:text-2xl font-bold mb-1">{service.name}</h2>
          <p className="text-gray-700">{service.modalDescription}</p>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default ServiceModal;
