
import React from 'react';
import { motion } from 'framer-motion';
import PhoneInput from 'react-phone-input-2';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { useForm, Controller, useWatch } from 'react-hook-form';
import NavigationButtonLink from '../../components/NavigationButtonLink/NavigationButtonLink';
import enquirySubjectCategories from '../../data/EnquirySubjectCategories'

interface FormData {
    name: string;
    email: string;
    phone: string;
    country: { label: string; value: string } | null;
    enquirySubject: { label: string; value: string } | null;
    enquirySubcategory: { label: string; value: string } | null;
    description: string;
}

const Contact: React.FC = () => {
    const { control, handleSubmit, reset, formState: { errors } } = useForm<FormData>({
        defaultValues: {
            name: '',
            email: '',
            phone: '',
            country: { label: 'Australia', value: 'Australia' },
            enquirySubject: null,
            enquirySubcategory: null,
            description: '',
        },
    });

    const enquirySubject = useWatch({
        control,
        name: 'enquirySubject',
    });

    const subcategoryOptions = enquirySubjectCategories.find(category => category.value === enquirySubject?.value)?.subcategories || [];

    const countryOptions = countryList().getData();

    const handleChange = (data: FormData) => {
        console.log(data);
        reset();
    };

    const formVariants = {
        hidden: { opacity: 0, scale: 0.95 },
        visible: { opacity: 1, scale: 1 },
        exit: { opacity: 0, scale: 0.95 },
    };

    return (
        <motion.div
            className="container mx-auto px-4 py-10 md:py-20"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
        >
            <motion.div
                className="grid grid-cols-1 md:grid-cols-3 items-center mb-6"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
            >
                <div className="flex justify-end items-center px-4 mb-4 md:mb-0 order-1 md:order-3">
                    <NavigationButtonLink to="/" aria-label="Navigate back to Home">Back to Home</NavigationButtonLink>
                </div>

                <div className="text-center px-4 order-2">
                    <h2 className="text-4xl md:text-5xl font-bold mb-4">Contact Us</h2>
                </div>

                <div className="hidden md:block order-1"></div>
            </motion.div>

            <motion.form
                onSubmit={handleSubmit(handleChange)}
                className="max-w-2xl mx-auto bg-white p-6 rounded shadow-lg"
                variants={formVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                transition={{duration: 0.5}}
            >
                <div className="mb-4">
                    <label className="block text-gray-700 font-bold">Name</label>
                    <Controller
                        name="name"
                        control={control}
                        rules={{required: 'Name is required'}}
                        render={({field}) => (
                            <input
                                type="text"
                                {...field}
                                className={`w-full mt-1 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                    errors.name ? 'border-red-500' : 'border-gray-300'
                                }`}
                                placeholder="Your Name"
                            />
                        )}
                    />
                    {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>}
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 font-bold">Email</label>
                    <Controller
                        name="email"
                        control={control}
                        rules={{
                            required: 'Email is required',
                            pattern: {
                                value: /^\S+@\S+$/i,
                                message: 'Invalid email address',
                            },
                        }}
                        render={({field}) => (
                            <input
                                type="email"
                                {...field}
                                className={`w-full mt-1 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                    errors.email ? 'border-red-500' : 'border-gray-300'
                                }`}
                                placeholder="you@example.com"
                            />
                        )}
                    />
                    {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>}
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 font-bold">Phone</label>
                    <Controller
                        name="phone"
                        control={control}
                        rules={{required: 'Phone number is required'}}
                        render={({field}) => (
                            <PhoneInput
                                country={'au'}
                                value={field.value}
                                onChange={field.onChange}
                                inputStyle={{
                                    width: '100%',
                                    height: '40px',
                                    borderRadius: '0.375rem',
                                    border: '1px solid #D1D5DB',
                                }}
                                containerStyle={{marginTop: '0.25rem'}}
                                buttonStyle={{
                                    border: '1px solid #D1D5DB',
                                    borderRadius: '0.375rem',
                                }}
                                inputProps={{
                                    name: 'phone',
                                    required: true,
                                    className: 'focus:outline-none focus:ring-2 focus:ring-blue-500',
                                    placeholder: '1234567890',
                                }}
                            />
                        )}
                    />
                    {errors.phone && <p className="text-red-500 text-sm mt-1">{errors.phone.message}</p>}
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 font-bold">Country</label>
                    <Controller
                        name="country"
                        control={control}
                        rules={{required: 'Country is required'}}
                        render={({field}) => (
                            <Select
                                {...field}
                                options={countryOptions}
                                defaultValue={{label: 'Australia', value: 'Australia'}}
                                classNamePrefix="react-select"
                                placeholder="Select Country"
                                styles={{
                                    control: (provided, state) => ({
                                        ...provided,
                                        marginTop: '0.25rem',
                                        borderColor: state.isFocused
                                            ? '#3B82F6'
                                            : '#D1D5DB',
                                        boxShadow: state.isFocused ? '0 0 0 2px rgba(59, 130, 246, 0.5)' : provided.boxShadow,
                                        '&:hover': {
                                            borderColor: '#3B82F6',
                                        },
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        maxHeight: '200px',
                                        overflowY: 'auto'
                                    }),
                                }}
                                isClearable
                            />
                        )}
                    />
                    {errors.country && <p className="text-red-500 text-sm mt-1">{errors.country.message}</p>}
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 font-bold">Enquiry Subject</label>
                    <Controller
                        name="enquirySubject"
                        control={control}
                        rules={{required: 'Enquiry subject is required'}}
                        render={({field}) => (
                            <Select
                                {...field}
                                options={enquirySubjectCategories.map(category => ({
                                    value: category.value,
                                    label: category.label,
                                }))}
                                placeholder="Select Subject"
                                classNamePrefix="react-select"
                                styles={{
                                    control: (provided, state) => ({
                                        ...provided,
                                        marginTop: '0.25rem',
                                        borderColor: state.isFocused
                                            ? '#3B82F6'
                                            : '#D1D5DB',
                                        boxShadow: state.isFocused ? '0 0 0 2px rgba(59, 130, 246, 0.5)' : provided.boxShadow,
                                        '&:hover': {
                                            borderColor: '#3B82F6',
                                        },
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        maxHeight: '200px',
                                    }),
                                }}
                            />
                        )}
                    />
                    {errors.enquirySubject && (
                        <p className="text-red-500 text-sm mt-1">{errors.enquirySubject.message}</p>
                    )}
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 font-bold">Enquiry Subcategory</label>
                    <Controller
                        name="enquirySubcategory"
                        control={control}
                        rules={{
                            required: enquirySubject ? 'Enquiry subcategory is required' : false
                        }}
                        render={({field}) => (
                            <Select
                                {...field}
                                options={subcategoryOptions}
                                placeholder={enquirySubject ? "Select Subcategory" : "Select Enquiry Subject First"}
                                classNamePrefix="react-select"
                                isDisabled={!enquirySubject}
                                styles={{
                                    control: (provided, state) => ({
                                        ...provided,
                                        marginTop: '0.25rem',
                                        borderColor: state.isFocused
                                            ? '#3B82F6'
                                            : '#D1D5DB',
                                        boxShadow: state.isFocused ? '0 0 0 2px rgba(59, 130, 246, 0.5)' : provided.boxShadow,
                                        '&:hover': {
                                            borderColor: '#3B82F6',
                                        },
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        maxHeight: '200px',
                                        overflowY: 'auto',
                                    }),
                                    menuList: (provided) => ({
                                        ...provided,
                                        maxHeight: '200px',
                                        overflowY: 'auto',
                                    }),
                                }}
                                isClearable
                            />
                        )}
                    />
                    {errors.enquirySubcategory && (
                        <p className="text-red-500 text-sm mt-1">{errors.enquirySubcategory.message}</p>
                    )}
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 font-bold">Description</label>
                    <Controller
                        name="description"
                        control={control}
                        rules={{required: 'Description is required'}}
                        render={({field}) => (
                            <textarea
                                {...field}
                                className={`w-full mt-1 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                    errors.description ? 'border-red-500' : 'border-gray-300'
                                }`}
                                rows={5}
                                placeholder="Describe your enquiry..."
                            ></textarea>
                        )}
                    />
                    {errors.description && (
                        <p className="text-red-500 text-sm mt-1">{errors.description.message}</p>
                    )}
                </div>

                <div className="text-center">
                    <button
                        type="submit"
                        className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
                    >
                        Submit
                    </button>
                </div>
            </motion.form>
        </motion.div>
    );
};

export default Contact;
