import React from 'react';
import {Link} from 'react-router-dom';

interface NavigationButtonLinkProps {
    to?: string;
    onClick?: () => void;
    children: React.ReactNode;
    className?: string;
}

const NavigationButtonLink: React.FC<NavigationButtonLinkProps> = ({to, onClick, children, className}) => {

    if (to) {
        return (
            <Link
                to={to}
                className={`border-2 border-black text-black px-6 py-2 rounded-full hover:bg-gray-200 transition-colors duration-300 flex items-center justify-center ${className}`}
            >
                {children}
            </Link>
        );
    } else if (onClick) {
        return (
            <button
                onClick={onClick}
                className={`border-2 border-black text-black px-6 py-2 rounded-full hover:bg-gray-200 transition-colors duration-300 flex items-center justify-center ${className}`}
            >
                {children}
            </button>
        );
    } else {
        return null;
    }
};

export default NavigationButtonLink;
