import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import servicesData from '../../data/servicesData';
import ServiceCard from '../../components/ServiceCard/ServiceCard';
import ServiceModal from '../../components/ServiceModal/ServiceModal';
import NavigationButtonLink from '../../components/NavigationButtonLink/NavigationButtonLink';

interface ServiceDetail {
  name: string;
  description: string;
  image: string;
  modalDescription: string;
}

interface ServiceModalProps {
  service?: ServiceDetail;
  onClose: () => void;
}

const Service: React.FC = () => {
  const { category } = useParams<{ category: string }>();
  const [selectedService, setSelectedService] = useState<ServiceDetail | null>(null);

  const categoryData = servicesData.find(
    (item) => item.category.toLowerCase() === category?.toLowerCase()
  );

  if (!categoryData) {
    return (
      <motion.div
        className="container mx-auto px-4 py-20 text-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-3xl font-bold mb-4">Service Not Found</h2>
        <p className="text-gray-700">
          The service you are looking for does not exist. Please check the URL or return to the home page.
        </p>
      </motion.div>
    );
  }

  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.3,
      },
    },
    exit: {
      transition: {
        staggerChildren: 0.2,
        staggerDirection: -1,
      },
    },
  };

  const headingVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -50 },
  };

  const cardsContainerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.3,
        delayChildren: 0.2,
      },
    },
    exit: {
      transition: {
        staggerChildren: 0.2,
        staggerDirection: -1,
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 },
  };

  return (
      <motion.div
          className="container mx-auto px-4 py-10 md:py-20"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
      >
        <motion.div
            className="grid grid-cols-1 md:grid-cols-3 items-center mb-6"
            variants={headingVariants}
            transition={{duration: 1}}
        >
          <div className="flex justify-end items-center px-4 mb-4 md:mb-0 order-1 md:order-3">
            <NavigationButtonLink to="/" aria-label="Navigate back to Home">Back to Home</NavigationButtonLink>
          </div>
          <div className="text-center px-4 order-2">
            <h1 className="text-4xl md:text-5xl font-bold mb-2">
              {categoryData.category}
            </h1>
            <h2 className="text-xl md:text-2xl font-semibold mb-0">
              {categoryData.serviceSubtitle}
            </h2>
          </div>
          <div className="hidden md:block order-1"></div>
        </motion.div>

        <motion.div
            className="mb-12 px-4"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{duration: 1, delay: 0.5}}
        >
          <p className="text-base md:text-xl text-gray-700 text-justify">
            {categoryData.serviceDescription}
          </p>
        </motion.div>

        <motion.div
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
            variants={cardsContainerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
          {categoryData.services.map((service, index) => (
              <motion.div
                  key={index}
                  variants={cardVariants}
                  transition={{duration: 1.2, ease: 'easeOut'}}
              >
                <ServiceCard
                    image={service.image}
                    title={service.name}
                    description={service.description}
                    onClick={() => setSelectedService(service)}
                />
              </motion.div>
          ))}
        </motion.div>

        <AnimatePresence>
          {selectedService && (
              <ServiceModal
                  service={selectedService}
                  onClose={() => setSelectedService(null)}
              />
          )}
        </AnimatePresence>
      </motion.div>
  );
};

export default Service;
