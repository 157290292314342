import exp from "node:constants";

interface SubcategoryOption {
    value: string;
    label: string;
}

interface EnquirySubjectCategory {
    value: string;
    label: string;
    subcategories: SubcategoryOption[];
}

const enquirySubjectCategories: EnquirySubjectCategory[] = [
    {
        value: 'Pre-Production',
        label: 'Pre-Production',
        subcategories: [
            { value: 'Concept Development', label: 'Concept Development' },
            { value: 'Scriptwriting', label: 'Scriptwriting' },
            { value: 'Storyboarding', label: 'Storyboarding' },
            { value: 'Casting', label: 'Casting' },
            { value: 'Location Scouting', label: 'Location Scouting' },
            { value: 'Budgeting', label: 'Budgeting' },
            { value: 'Scheduling', label: 'Scheduling' },
        ],
    },
    {
        value: 'Production',
        label: 'Production',
        subcategories: [
            { value: 'Directing', label: 'Directing' },
            { value: 'Cinematography', label: 'Cinematography' },
            { value: 'Set Design', label: 'Set Design' },
            { value: 'Lighting', label: 'Lighting' },
            { value: 'Sound Recording', label: 'Sound Recording' },
            { value: 'Makeup and Wardrobe', label: 'Makeup and Wardrobe' },
            { value: 'Production Management', label: 'Production Management' },
        ],
    },
    {
        value: 'Post-Production',
        label: 'Post-Production',
        subcategories: [
            { value: 'Editing', label: 'Editing' },
            { value: 'Sound Design', label: 'Sound Design' },
            { value: 'Music Scoring', label: 'Music Scoring' },
            { value: 'Visual Effects', label: 'Visual Effects' },
            { value: 'Color Grading', label: 'Color Grading' },
            { value: 'Subtitle and Dubbing', label: 'Subtitle and Dubbing' },
        ],
    },
    {
        value: 'General',
        label: 'General',
        subcategories: [
            { value: 'Consultation', label: 'Consultation' },
            { value: 'Other', label: 'Other' },
        ],
    },
];

export default enquirySubjectCategories