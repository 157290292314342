import React from 'react';
import {motion} from 'framer-motion';
import NavigationButtonLink from '../../components/NavigationButtonLink/NavigationButtonLink';

const About = () => {
    return (
        <div className="container mx-auto px-4 py-10 md:py-20">

            <motion.div
                className="grid grid-cols-1 md:grid-cols-3 items-center mb-6"
                initial={{opacity: 0, y: -50}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 1}}
            >
                <div className="flex justify-end items-center px-4 mb-4 md:mb-0 order-1 md:order-3">
                    <NavigationButtonLink to="/" aria-label="Navigate back to Home">Back to Home</NavigationButtonLink>
                </div>

                <div className="text-center px-4 order-2">
                    <h2 className="text-4xl md:text-5xl font-bold mb-4">About Us</h2>
                </div>

                <div className="hidden md:block order-1"></div>
            </motion.div>

            <motion.div
                className="max-w-3xl mx-auto text-justify"
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{duration: 1, delay: 0.5}}
            >
                <p className="text-gray-700">
                    Creative Production Studio is a new film production company dedicated to
                    delivering high-quality cinematic experiences. Our team of passionate
                    professionals works collaboratively to bring your stories to life.
                </p>
            </motion.div>
        </div>
    );
};

export default About;
