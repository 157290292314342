// src/data/servicesData.ts

import preproductionImage from '../assets/images/preproduction.jpg';
import productionImage from '../assets/images/production.jpg';
import postproductionImage from '../assets/images/postproduction.jpg';
import cinematographyImg from '../assets/images/cinematography.jpg';
import conceptDevelopmentImg from '../assets/images/conceptDevelopment.jpg';
import scriptWritingImg from '../assets/images/scriptWriting.jpg';
import storyBoardingImg from '../assets/images/storyBoarding.jpg';
import castingImg from '../assets/images/casting.jpg';
import locationScoutingImg from '../assets/images/locationScouting.jpg';
import budgetingImg from '../assets/images/budgeting.jpg';
import schedulingImg from '../assets/images/scheduling.jpg';
import directionImg from '../assets/images/directing.jpg';
import setDesignImg from '../assets/images/setDesign.jpg';
import lightingImg from '../assets/images/lighting.jpg';
import soundRecordingImg from '../assets/images/soundRecording.jpg';
import makeupImg from '../assets/images/makeup.jpg';
import productionManagementImg from '../assets/images/productionManagement.jpg';
import editingImg from '../assets/images/editing.jpg';
import soundDesignImg from '../assets/images/soundDesign.jpg';
import musicScoringImg from '../assets/images/musicScoring.jpg';
import vfxImg from '../assets/images/vfx.jpg';
import colorGradingImg from '../assets/images/colorGrading.jpg';
import dubbingImg from '../assets/images/dubbing.jpg';

interface ServiceDetail {
  name: string;
  description: string;
  image: string;
  modalDescription: string;
}

interface ServiceCategory {
  category: string;
  homeDescription: string;
  serviceSubtitle: string;
  serviceDescription: string;
  image: string;
  services: ServiceDetail[];
}

const servicesData: ServiceCategory[] = [
  {
    category: 'Pre-Production',
    homeDescription:
      'We lay the groundwork to turn your vision into reality with meticulous planning and creative development.',
    serviceSubtitle: 'Turn Your Vision into Reality',
    serviceDescription:
      'In pre-production, we help transform your ideas into actionable plans. Our team works with you to develop compelling concepts, write scripts, storyboard scenes, and organize everything needed to bring your project to life. Imagine starting with a simple idea and seeing it evolve into a detailed plan ready for execution. For instance, if you\'re producing a commercial, we\'ll help scout the perfect locations, cast the ideal actors, and plan the shots that will captivate your audience.',
    image: preproductionImage,
    services: [
      {
        name: 'Concept Development',
        description:
          'Developing the initial idea or script for a project.',
        image: conceptDevelopmentImg,
        modalDescription: "Temporary value",
      },
      {
        name: 'Scriptwriting',
        description:
          'Writing and refining scripts.',
        image: scriptWritingImg,
        modalDescription: "Temporary value",
      },
      {
        name: 'Storyboarding',
        description:
          'Creating visual plans of scenes.',
        image: storyBoardingImg,
        modalDescription: "Temporary value",
      },
      {
        name: 'Casting',
        description:
          'Finding and hiring actors and other talent.',
        image: castingImg,
        modalDescription: "Temporary value",
      },
      {
        name: 'Location Scouting',
        description:
          'Finding and securing filming locations.',
        image: locationScoutingImg,
        modalDescription: "Temporary value",
      },
      {
        name: 'Budgeting',
        description:
          'Estimating the cost of production and planning the budget.',
        image: budgetingImg,
        modalDescription: "Temporary value",
      },
      {
        name: 'Scheduling',
        description:
          'Planning the timeline for the entire production process.',
        image: schedulingImg,
        modalDescription: "Temporary value",
      },
    ],
  },
  {
    category: 'Production',
    homeDescription:
      'This is where the magic happens! Our skilled crew brings your project to life with precision and creativity.',
    serviceSubtitle: 'Creating Unforgettable Moments',
    serviceDescription:
      'This is where the magic happens! During production, our skilled crew handles everything from directing to cinematography, ensuring each scene is shot to perfection. With state-of-the-art equipment and a knack for creativity, we capture breathtaking visuals and sound. Picture a dynamic music video shoot: our team coordinates every element, from lighting and set design to sound recording, bringing your vision to life with vivid, unforgettable imagery.',
    image: productionImage,
    services: [
      {
        name: 'Directing',
        description:
          'Overseeing the creative aspects of the project.',
        image: directionImg,
        modalDescription: "Temporary value",
      },
      {
        name: 'Cinematography',
        description:
          'Capturing the visual aspects of the project.',
        image: cinematographyImg,
        modalDescription: "Temporary value",
      },
      {
        name: 'Set Design',
        description:
          'Designing and constructing the sets.',
        image: setDesignImg,
        modalDescription: "Temporary value",
      },
      {
        name: 'Lighting',
        description:
          'Planning and setting up the lighting for scenes.',
        image: lightingImg,
        modalDescription: "Temporary value",
      },
      {
        name: 'Sound Recording',
        description:
          'Capturing audio during filming.',
        image: soundRecordingImg,
        modalDescription: "Temporary value",
      },
      {
        name: 'Makeup and Wardrobe',
        description:
          'Preparing actors with appropriate makeup and costumes.',
        image: makeupImg,
        modalDescription: "Temporary value",
      },
      {
        name: 'Production Management',
        description:
          'Coordinating all aspects of the shoot to keep it on schedule and within budget.',
        image: productionManagementImg,
        modalDescription: "Temporary value",
      },
    ],
  },
  {
    category: 'Post-Production',
    homeDescription:
      'We polish your project to perfection, ensuring it looks and sounds incredible.',
    serviceSubtitle: 'Crafting the Final Masterpiece',
    serviceDescription:
      'In post-production, we polish your project to perfection. Our experts edit footage, design soundscapes, create visual effects, and color-grade the final product to ensure it looks and sounds incredible. Imagine transforming raw footage into a stunning documentary with seamless transitions, engaging soundtracks, and powerful visuals that tell your story with impact. From indie films to corporate videos, our post-production services ensure your project shines.',
    image: postproductionImage,
    services: [
      {
        name: 'Editing',
        description:
          'Cutting and assembling the raw footage into a final product.',
        image: editingImg,
        modalDescription: "Temporary value",
      },
      {
        name: 'Sound Design',
        description:
          'Creating and editing sound effects and audio.',
        image: soundDesignImg,
        modalDescription: "Temporary value",
      },
      {
        name: 'Music Scoring',
        description:
          'Composing or licensing music for the project.',
        image: musicScoringImg,
        modalDescription: "Temporary value",
      },
      {
        name: 'Visual Effects',
        description:
          'Adding special effects and CGI.',
        image: vfxImg,
        modalDescription: "Temporary value",
      },
      {
        name: 'Color Grading',
        description:
          'Adjusting the color and tone of the footage.',
        image: colorGradingImg,
        modalDescription: "Temporary value",
      },
      {
        name: 'Subtitle and Dubbing',
        description:
          'Adding subtitles or dubbing the dialogue into different languages.',
        image: dubbingImg,
        modalDescription: "Temporary value",
      },
    ],
  },
];

export default servicesData;
