import React from 'react';
import { motion } from 'framer-motion';
import ServiceCard from '../../components/ServiceCard/ServiceCard';
import servicesData from '../../data/servicesData';

const Home: React.FC = () => {

  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
      },
    },
    exit: {
      transition: {
      },
    },
  };

  const headingVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -50 },
  };

  const cardsContainerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.5,
      },
    },
    exit: {
      transition: {
        staggerChildren: 0.3,
        staggerDirection: -1,
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 },
  };

  return (
    <motion.div
      className="container mx-auto px-4 py-10 md:py-20"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <motion.div
          className="text-center mb-12"
          variants={headingVariants}
          transition={{duration: 1}}
      >
        <h1 className="text-4xl md:text-5xl font-bold mb-4">
          Welcome to
        </h1>
        <h1 className="text-2xl md:text-5xl font-bold mb-4">
          Creative Production Studio
        </h1>
        <p className="text-xl text-gray-700">
          We bring your visions to life with exceptional film production services.
        </p>
      </motion.div>

      <motion.h2
          className="text-2xl md:text-4xl font-bold text-center mb-12"
          variants={headingVariants}
        transition={{ duration: 1, delay: 0.2 }}
      >
        Our Services
      </motion.h2>

      <motion.div
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
        variants={cardsContainerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {servicesData.map((category) => (
          <motion.div
            key={category.category}
            variants={cardVariants}
            transition={{ duration: 1.2, ease: 'easeOut' }}
          >
            <ServiceCard
              image={category.image}
              title={category.category}
              description={category.homeDescription}
              link={`/service/${category.category.toLowerCase()}`}
            />
          </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
};

export default Home;
