import React from 'react';
import { motion } from 'framer-motion';
import NavigationButtonLink from '../../components/NavigationButtonLink/NavigationButtonLink';


interface ServiceCardLinkProps {
    image: string;
    title: string;
    description: string;
    link: string;
    onClick?: never;
}

interface ServiceCardButtonProps {
    image: string;
    title: string;
    description: string;
    onClick: () => void;
    link?: never;
}

type ServiceCardProps = ServiceCardLinkProps | ServiceCardButtonProps;


const ServiceCard: React.FC<ServiceCardProps> = ({
  image,
  title,
  description,
  link,
  onClick,
}) => {

  const cardContent = (
        <div className="flex flex-col h-full">
            <img
                src={image}
                alt={title}
                className="w-full h-48 object-cover"
                loading="lazy"
            />
            <div className="p-6 flex flex-col justify-between flex-1">
                <div>
                    <h3 className="text-2xl font-semibold mb-2">{title}</h3>
                    <p className="text-gray-700 text-justify">{description}</p>
                </div>
                <div className="mt-4 text-right">
                    {link ? (
                        <div className="lg:hidden">
                            <span
                                className="inline-block border-2 border-black text-black px-6 py-2 rounded-full hover:bg-gray-200 transition-colors duration-300">
                                Know More &rarr;
                            </span>
                        </div>
                    ) : (
                        onClick && (
                            <div className="lg:hidden">
                                <NavigationButtonLink onClick={onClick}>
                                    Know More &rarr;
                                </NavigationButtonLink>
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );

    if (link) {
        return (
            <motion.div
                className="bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg duration-300 cursor-pointer"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
            >
                <NavigationButtonLink to={link} className="block h-full border-none hover:bg-transparent">
                    {cardContent}
                </NavigationButtonLink>
            </motion.div>
        );
    } else if (onClick) {
        return (
            <motion.div
                className="flex flex-col bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg duration-300 cursor-pointer"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
                onClick={onClick}
            >
                {cardContent}
            </motion.div>
        );
    } else {
        return (
            <motion.div
                className="flex flex-col bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg duration-300"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
            >
                {cardContent}
            </motion.div>
        );
    }
};

export default ServiceCard;