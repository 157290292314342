import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import footerImage from '../../assets/images/flags.png'

import {ReactComponent as Logo} from '../../assets/images/CreativeProductionStudio_HighQuality.svg';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white mt-8">
      <div className="container md:mx-10 px-4 py-6 flex flex-col md:flex-row md:justify-between items-center">
        <div className="flex flex-col md:flex-row items-center md:items-start w-full md:w-2/5 mb-6 md:mb-0">
          <img src={footerImage} alt="Footer Logo" className="w-32 sm:w-40 md:w-32 lg:w-40 h-auto mb-4 md:mb-0"/>
          <p className="text-justify md:ml-4">
            We acknowledge the Traditional Owners of the land where we work and live. We pay our respects to Elders
            past, present and emerging. We celebrate the stories, culture and traditions of Aboriginal and Torres Strait
            Islander Elders of all communities who also work and live on this land.
          </p>
        </div>
        <div className="hidden md:block mb-6 md:mb-0 md:w-1/3 md:justify-center">
          <Logo className="h-16 md:h-24 lg:h-32 w-auto object-contain flex-shrink-0 p-2 bg-white rounded-full"/>
        </div>
        <div className="flex flex-col items-center md:items-end">
          <span className="mb-4 text-lg font-semibold">Follow us on:</span>
          <div className="flex space-x-4">
            <a href="#" aria-label="Facebook" className="hover:text-yellow-400">
              <FaFacebook size={24}/>
            </a>
            <a href="#" aria-label="Twitter" className="hover:text-yellow-400">
              <FaTwitter size={24}/>
            </a>
            <a href="#" aria-label="Instagram" className="hover:text-yellow-400">
              <FaInstagram size={24}/>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
